import { Layout, Menu } from 'antd';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { FULL_PERMISSIONS_API_FORM } from '../../constants/permissions';
import { EAuthPermissionAndObject } from '../../models/admin-user';
import { AdminPermission, AdminUser } from '../../repositories/types';
import { ROUTE } from '../../router';
import { mapUserPermissionsToUIForm } from '../../services/AdminUserService';

import styles from './Nav.module.css';

type NavPropsTypes = {
  adminRoles?: AdminUser['roles'];
  adminPermissions?: AdminUser['permissions'];
};

const Nav = ({ adminRoles, adminPermissions }: NavPropsTypes) => {
  const router = useRouter();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([router.pathname]);
  const [permissions, setPermissions] = useState<EAuthPermissionAndObject[]>(
    []
  );

  const isSuperAdmin = adminRoles?.[0] === 'SUPER_ADMIN';

  useEffect(() => {
    setSelectedKeys([router.pathname]);
  }, [router.pathname]);

  useEffect(() => {
    if (isSuperAdmin) {
      setPermissions(
        mapUserPermissionsToUIForm(FULL_PERMISSIONS_API_FORM).map(
          (permission) => permission.value as EAuthPermissionAndObject
        )
      );
      return;
    }

    if (adminPermissions) {
      setPermissions(
        mapUserPermissionsToUIForm(
          adminPermissions.filter(
            (permission): permission is AdminPermission => !!permission
          )
        ).map((permission) => permission.value as EAuthPermissionAndObject)
      );
    }
  }, [isSuperAdmin, adminPermissions]);

  const checkPermitted = (permission: EAuthPermissionAndObject) => {
    return permissions.includes(permission);
  };

  return (
    <Layout.Sider theme={'light'} width={'200px'}>
      <Menu
        selectedKeys={selectedKeys}
        mode={'vertical'}
        className={styles['menu-list']}
      >
        <Menu.ItemGroup key={'상품'} title={'상품관리'}>
          <Menu.Divider />
          <Menu.Item
            disabled={!checkPermitted(EAuthPermissionAndObject.EDIT_PRODUCTS)}
            key={ROUTE.products.new}
            onClick={() => router.push(ROUTE.products.new)}
          >
            {'상품등록'}
          </Menu.Item>
          <Menu.Item
            disabled={!checkPermitted(EAuthPermissionAndObject.READ_PRODUCTS)}
            key={ROUTE.products.list}
            onClick={() => router.push(ROUTE.products.list)}
          >
            {'상품목록'}
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key={'주문'} title={'주문관리'}>
          <Menu.Divider />
          <Menu.Item
            disabled={!checkPermitted(EAuthPermissionAndObject.READ_ORDERS)}
            key={ROUTE.orderLineItems.list}
            onClick={() => router.push(ROUTE.orderLineItems.list)}
          >
            {'개별주문목록'}
          </Menu.Item>
          <Menu.Item
            disabled={!checkPermitted(EAuthPermissionAndObject.READ_ORDERS)}
            key={ROUTE.orders.list}
            onClick={() => router.push(ROUTE.orders.list)}
          >
            {'정산목록'}
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key={'케미회원'} title={'케미 회원관리'}>
          <Menu.Divider />
          <Menu.Item
            disabled={!checkPermitted(EAuthPermissionAndObject.READ_KEMI_USERS)}
            key={ROUTE.kemiUsers.list}
            onClick={() => router.push(ROUTE.kemiUsers.list)}
          >
            {'케미 회원목록'}
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key={'어드민회원'} title={'어드민 회원관리'}>
          <Menu.Divider />
          <Menu.Item
            disabled={
              !checkPermitted(EAuthPermissionAndObject.READ_ADMIN_USERS)
            }
            key={ROUTE.adminUsers.list}
            onClick={() => router.push(ROUTE.adminUsers.list)}
          >
            {'어드민 회원목록'}
          </Menu.Item>
        </Menu.ItemGroup>
        <Menu.ItemGroup key={'시스템관리'} title={'시스템관리'}>
          <Menu.Divider />
          <Menu.Item
            disabled={!checkPermitted(EAuthPermissionAndObject.READ_HISTORIES)}
            key={ROUTE.system.history.list}
            onClick={() => router.push(ROUTE.system.history.list)}
          >
            {'시스템 히스토리목록'}
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    </Layout.Sider>
  );
};

export default Nav;
